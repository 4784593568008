export const EPAY_TAB_STATE = {
  EPAY: 1,
  TAWI: 2,
  PND: 3,
  OTHER: 4
}

export const PORTAL_TAB_STATE = {
  PROFILE: 'profile',
  DASHBOARD: 'dashboard',
  NEWS: 'news'
}

export const ELEAVE_TAB_STATE = {
  EMPLOYEE_INFOMATION: 'employee-infomation',
  LEAVE_TYPE: 'leave-type',
  LEAVE_GROUP: 'leave-group',
  LEAVE_PERIOD: 'leave-period',
  REPORT_SETTING: 'report-setting'
}

export const ETIME_TAB_STATE = {
  TIME: 'time',
  SPECIAL_ALLOWANCE: 'special-allowance'
}

export const LOGIN_TAB_STATE = {
  MAIN: 'main',
  FORGOT_PASSWORD: 'forgot-password',
  CHANGE_PASSWORD: 'change-password'
}

export const ELEAVE_HR_TAB_STATE = {
  LEAVE_IMPROVE: 'leave-improve',
  LEAVE_EMPLOYEE: 'leave-employee',
  LEAVE_APPROVE: 'leave-approve',
  LEAVE_REPORT: 'leave-report'
}

export const ELEAVE_REPORT_TAB_STATE = {
  LEAVE_REPORT_TRANSACTION: 'leave-report-transaction',
  LEAVE_REPORT_SUMMARY: 'leave-report-summary'
}

export const ELEAVE_EMPLOYEE_TAB_STATE = {
  MY_LEAVE: 'my-leave',
  HISTORY: 'history',
  APPROVE: 'approve'
}

export const ETIME_HR_TAB_STATE = {
  IMPORT_FILE: 'import-file',
  APPROVE: 'approve'
}
