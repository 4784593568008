export default {
  form: {
    start: {
      placeholder: 'เริ่มต้น',
      label: 'เริ่มต้น'
    },
    end: {
      placeholder: 'สิ้นสุด',
      label: 'สิ้นสุด'
    },
    status: {
      placeholder: 'สถานะ',
      label: 'สถานะ'
    },
    reqStart: {
      placeholder: 'Start time',
      label: 'Start time'
    },
    reqEnd: {
      placeholder: 'End time',
      label: 'End time'
    },
    timeIn: {
      placeholder: 'Start time',
      label: 'Start time'
    },
    timeOut: {
      placeholder: 'End time',
      label: 'End time'
    },
    date: {
      placeholder: 'Date',
      label: 'Date'
    },
    allowanceUseDate: {
      placeholder: 'วันที่ร้องขอเงินได้พิเศษ',
      label: 'วันที่'
    },
    value: {
      placeholder: 'กรอกจำนวนเงินได้พิเศษ',
      label: 'ค่าเงินได้พิเศษ'
    },
    reason: {
      placeholder: 'กรอกเหตุผล',
      label: 'เหตุผล'
    },
    allowanceCode: {
      placeholder: 'เลือกประเภทเงินได้พิเศษ',
      label: 'ประเภทเงินได้พิเศษ'
    }
  },
  table: {
    status: 'สถานะ',
    action: 'ดำเนินการ',
    date: 'วันที่',
    title: 'ปะรเภทคำขอ',
    remark: 'หมายเหตุ',
    requester: 'ผู้ขอ'
  },
  modal: {
    approve: 'คุณต้องการอนุมัติรายการลาที่เลือกหรือไม่?',
    reject: 'คุณต้องการปฏิเสธรายการลาที่เลือกหรือไม่?'
  }
}
