export default {
  title: 'บริหารเวลาออนไลน์',
  home: 'หน้าหลัก',
  tabs: {
    time: 'บริหารเวลา',
    'special-allowance': 'เงินได้พิเศษ'
  },
  'table-branch': {
    id: 'ลำดับที่',
    branchCode: 'รหัสสาขา',
    describe: 'รายละเอียดเพิ่มเติม'
  },
  'main-setting': {
    'edit-title': 'แก้ไขตั่งค่าบริหารเวลา',
    'sub-title': { ot: 'ตั่งค่าโอที', time: 'ตั่งค่าเวลา' },
    form: {
      enableCardMapping: 'การจับคู่พนักงานกับบัตรพนักงาน',
      enableAppEditTime: 'อนุญาติผู้อนุมัติแก้ไขเวลา',
      division: 'ฝ่าย',
      department: 'แผนก',
      workflowCode: 'รหัสสายอนุมัติ'
    }
  },
  'table-special-allowance': {
    id: 'id',
    code: 'Allowance code',
    name: 'Allowance name',
    costCenterName: 'Cost center name',
    'add-button': 'เพิ่มเงินได้พิเศษ'
  }
}
