export default {
  form: {
    start: {
      placeholder: 'Start',
      label: 'Start'
    },
    end: {
      placeholder: 'To',
      label: 'To'
    },
    status: {
      placeholder: 'Status',
      label: 'Status'
    },
    reqStart: {
      placeholder: 'Start time',
      label: 'Start time'
    },
    reqEnd: {
      placeholder: 'End time',
      label: 'End time'
    },
    timeIn: {
      placeholder: 'Start time',
      label: 'Start time'
    },
    timeOut: {
      placeholder: 'End time',
      label: 'End time'
    },
    date: {
      placeholder: 'Date',
      label: 'Date'
    },
    allowanceUseDate: {
      placeholder: 'Date',
      label: 'Date'
    },
    appEditTimeIn: {
      placeholder: 'Edit start time',
      label: 'Edit start time'
    },
    appEditTimeOut: {
      placeholder: 'Edit end time',
      label: 'Edit end time'
    },
    value: {
      placeholder: 'Insert special allowance value',
      label: 'Special allowance value'
    },
    reason: {
      placeholder: 'Insert request reason.',
      label: 'Reason'
    },
    allowanceCode: {
      placeholder: 'Select special allowance.',
      label: 'Special allowance'
    }
  },
  table: {
    status: 'Status',
    action: 'Action',
    date: 'Date',
    title: 'Title',
    remark: 'Remark',
    requester: 'Requester'
  },
  modal: {
    approve: 'Are you sure want to Approve ?',
    reject: 'Are you sure want to Reject ?'
  }
}
