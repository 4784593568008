import React from 'react'

export default [
  {
    path: '/',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-home-page" */ '@components/pages/management/HomePage'
      )
    ),
    exact: true
  },
  {
    path: '/callback',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-call-back-page" */ '@components/pages/main/CallbackPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-login-page" */ '@components/pages/management/LoginPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/approve-email',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-approve-email-page" */ '@components/pages/main/ApproveEmailPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/change-password',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-change-password-page" */ '@components/pages/management/ChangePasswordPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/forgot-password',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-forgot-password-page" */ '@components/pages/management/ForgotPasswordPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-self-service-page" */ '@components/pages/management/SelfServicePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/login',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-self-service-login-page" */ '@components/pages/management/SelfServicePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/login/:configId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-login-config-page" */ '@components/pages/management/LoginConfigPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/portal',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-portal-page" */ '@components/pages/management/PortalPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/portal/:configId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-portal-config-page" */ '@components/pages/management/PortalConfigPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/portal/news/add',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-portal-config-add-news-page" */ '@components/pages/management/AddNewsPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/portal/news/edit/:newsId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-portal-config-add-news-page" */ '@components/pages/management/EditNewsPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-payslip',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-payslip-page" */ '@components/pages/management/EPaySlipPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-payslip/:configId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-payslip-config-page" */ '@components/pages/management/EPaySlipConfigPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-certificate',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-certificate-page" */ '@components/pages/management/ECertificatePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-certificate/:configId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-certificate-config-page" */ '@components/pages/management/ECertificateConfigPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-certificate/:configId/edit/:certificateId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-certificate-config-edit-page" */ '@components/pages/management/ECertificateConfigPage/SuperECertificatePage/SuperEditEcetificate'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-certificate/:configId/add',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-certificate-config-add-page" */ '@components/pages/management/ECertificateConfigPage/SuperECertificatePage/SuperAddEcertificate'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/workflow-setting',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-workflow-setting-page" */ '@components/pages/management/WorkflowSettingPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-leave',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-leave-page" */ '@components/pages/management/ELeavePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-leave/:configId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-leave-config-page" */ '@components/pages/management/ELeaveConfigPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-leave/:configId/add-leave-type',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-leave-config-page" */ '@components/pages/management/ELeaveAddLeaveTypePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-leave/:configId/:leaveTypeId/edit-leave-type',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-leave-config-page" */ '@components/pages/management/ELeaveEditLeaveTypePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-leave/:configId/leave-group/add',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-leave-leave-group-add-page" */ '@components/pages/management/AddLeaveGroupPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-leave/:configId/leave-group/:leaveGroupId/edit',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-leave-leave-group-edit-page" */ '@components/pages/management/EditLeaveGroupPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/workflow-setting/add',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-workflow-setting-add-page" */ '@components/pages/management/AddWorkflowPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/workflow-setting/edit/:workflowId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-workflow-setting-edit-page" */ '@components/pages/management/EditWorkflowPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/audit-log',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-audit-log-page" */ '@components/pages/management/AuditLogPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/setting',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "main-setting-page" */ '@components/pages/main/UserSettingPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-time',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-time-page" */ '@components/pages/management/ETimePage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-time/:configId',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-time-config-page" */ '@components/pages/management/ETimeConfigPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-time/:configId/:branchCode/edit-main-setting',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-leave-config-page" */ '@components/pages/management/ETimeEditMainSettingPage'
      )
    ),
    exact: true
  },
  {
    path: '/:companyGroup/self-service/e-time/:configId/:code/edit-sp-allowance',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-time-edit-sp-allowance-page" */ '@components/pages/management/ETimeEditSpAllowancePage'
      )
    )
  },
  {
    path: '/:companyGroup/self-service/e-time/:configId/add-sp-allowance',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "management-e-time-add-sp-allowance-page" */ '@components/pages/management/ETimeAddSpAllowancePage'
      )
    )
  }
]
