export default {
  title: 'การบริหารจัดการวันลาออนไลน์ - มอบหมายการอนุมัติ',
  'title-time': 'การบริหารจัดการเวลาออนไลน์ - มอบหมายการอนุมัติ',
  searchForm: {
    delegater: {
      label: 'ผู้ดำเนินการแทน',
      placeholder: 'ผู้ดำเนินการแทน'
    },
    dateRange: {
      label: 'วันที่',
      placeholder: {
        formDate: 'ตั้งแต่วันที่',
        toDate: 'ถึงวันที่'
      }
    },
    button: {
      add: 'เพิ่ม'
    }
  },
  table: {
    column: {
      sort: 'ลำดับที่',
      approve: 'ผู้อนุมัติ',
      delegate: 'ผู้ดำเนินการแทน',
      fromdate: 'ตั้งแต่วันที่',
      todate: 'ถึงวันที่',
      action: 'ดำเนินการ'
    }
  },
  delegateForm: {
    titleAdd: 'เพิ่มผู้ดำเนินการแทน',
    titleEdit: 'แก้ไขผู้ดำเนินการแทน',
    form: {
      code: {
        label: 'รหัส',
        placeholder: 'รหัส'
      },
      status: {
        label: 'สถานะ',
        placeholder: 'สถานะ'
      },
      delegate: {
        label: 'ผู้ดำเนินการแทน',
        placeholder: 'ผู้ดำเนินการแทน'
      },
      startDate: {
        label: 'ตั้งแต่วันที่',
        placeholder: 'ตั้งแต่วันที่'
      },
      endDate: {
        label: 'ถึงวันที่',
        placeholder: 'ถึงวันที่'
      },
      isEnableEmail: {
        label: 'ส่งอีเมลแจ้ง',
        placeholder: 'ส่งอีเมลแจ้ง'
      },
      workflowType: {
        label: 'ประเภทเอกสาร',
        placeholder: 'ประเภทเอกสาร'
      }
    }
  },
  deleteModal: {
    title: 'ลบผู้ดำเนินการแทน',
    content: 'ต้องการลบผู้ดำเนินการแทนนี้ใช่หรือไม่?'
  }
}
