import styled from '@emotion/styled'
import { Button } from 'antd'
import { tint } from 'polished'
import { css } from '@emotion/react'

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'configTheme'
})`
  border-radius: 3px;
  height: 32px;
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 87px
    ${({ variant }) =>
      variant === 'blank' &&
      css`
        box-shadow: none;
        span {
          text-decoration: underline;
        }
      `};

  border-color: ${({ variant, configTheme, theme }) => {
    switch (variant) {
      case 'border':
        return configTheme && configTheme.mainColorCode
          ? configTheme?.mainColorCode
          : theme.button.borderColor
      case 'blank':
        return theme.button.primaryBorderColor
      case 'initial':
        return theme.button.initialBorderColor
      default:
        return theme.button.primaryBorderColor
    }
  }};

  background-color: ${({ variant, configTheme, theme }) => {
    switch (variant) {
      case 'border':
        return theme.button.borderBackground
      case 'blank':
        return theme.button.blankBackground
      case 'initial':
        return theme.button.initialBackground
      default:
        return configTheme && configTheme.mainColorCode
          ? configTheme?.mainColorCode
          : theme.button.primaryBackground
    }
  }};

  color: ${({ variant, configTheme, theme }) => {
    switch (variant) {
      case 'border':
        return configTheme && configTheme.mainColorCode
          ? configTheme?.mainColorCode
          : theme.button.borderTextColor
      case 'blank':
        return theme.button.blankColor
      case 'initial':
        return theme.common.textColor
      default:
        return configTheme && configTheme.mainColorCode
          ? theme.button.primaryTextColor
          : theme.button.primaryTextColor
    }
  }};

  &:hover,
  &:focus {
    border-color: ${({ variant, configTheme, theme }) => {
      switch (variant) {
        case 'border':
          return configTheme && configTheme.mainColorCode
            ? tint(0.3, configTheme?.mainColorCode)
            : theme.button.borderColor
        case 'blank':
          return theme.button.primaryBorderColor
        default:
          return theme.button.primaryBorderColor
      }
    }};

    background-color: ${({ variant, configTheme, theme }) => {
      switch (variant) {
        case 'border':
          return configTheme && configTheme.mainColorCode
            ? tint(0.3, configTheme?.mainColorCode)
            : theme.button.borderHoverBackground
        case 'blank':
          return theme.button.blankBackground
        default:
          return configTheme && configTheme.mainColorCode
            ? tint(0.3, configTheme?.mainColorCode)
            : theme.button.hoverBackground
      }
    }};

    color: ${({ variant, theme }) => {
      switch (variant) {
        case 'blank':
          return theme.button.blankColor
        default:
          return theme.button.primaryTextColor
      }
    }};
  }
`
