import styled from '@emotion/styled'
import { Radio } from 'antd'

export const StyledRadio = styled(Radio.Button, {
  shouldForwardProp: (prop) => prop !== 'configTheme'
})`
  word-break: normal;
  min-width: 90px;
  text-align: center;
  .ant-radio {
    .ant-radio-inner {
      border-color: ${({ theme }) => theme.radio.radioInnerColor};
      background-color: ${({ theme }) => theme.radio.radioInnerColor};
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      &::after {
        background-color: ${({ theme, configTheme }) =>
          configTheme && configTheme.activeColorCode
            ? configTheme.activeColorCode
            : theme.radio.radioCheckedColor};
      }
      border-color: ${({ theme }) => theme.radio.radioInnerColor};
    }
  }
`
