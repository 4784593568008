import { withConfigTheme } from '@core/hocs'
import { StyledRadio } from './RadioButton.styled'

const RadioButton = ({
  checked,
  value,
  name,
  disabled,
  className,
  onChange = () => {},
  size,
  configTheme,
  ...props
}) => {
  return (
    <StyledRadio
      size={size}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      configTheme={configTheme}
      className={className}
      value={value}
      name={name}
      {...props}
    />
  )
}

export default withConfigTheme(RadioButton)
