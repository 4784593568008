export default {
  GET_EVENT: {
    url: '/e-time/eTime/get-event',
    method: 'POST',
    isPublic: false
  },
  GET_APPROVE_ALL: {
    url: '/e-time/eTime/get-approve-all',
    method: 'POST',
    isPublic: false
  },
  GET_ACTION_ALL: {
    url: '/e-time/eTime/get-action-all',
    method: 'POST',
    isPublic: false
  },
  GET_SP_ALLOWANCE_OPTION: {
    url: '/e-time/setting/sp-allowance',
    method: 'GET',
    isPublic: false
  },
  POST_SP_ALLOWANCE: {
    url: '/e-time/special-allowance',
    method: 'POST',
    isPublic: false
  },
  POST_REQ_EDIT_TIME: {
    url: '/e-time/eTime/request-edit-time',
    method: 'POST',
    isPublic: false
  },

  POST_OT_REQ: {
    url: '/e-time/ot/request',
    method: 'POST',
    isPublic: false
  },

  PUT_OT_REQ: {
    url: '/e-time/ot/edit',
    method: 'PUT',
    isPublic: false
  },

  POST_OT_APPROVE: {
    url: '/e-time/ot/approve',
    method: 'POST',
    isPublic: false
  },
  POST_CANCEL_OT: {
    url: '/e-time/ot/cancel',
    method: 'POST',
    isPublic: false
  },
  POST_APPROVE: {
    url: '/e-time/approve',
    method: 'POST',
    isPublic: false
  },
  POST_EDIT_REQ_TIME: {
    url: '/e-time/eTime/edit-request-edit-time',
    method: 'POST',
    isPublic: false
  },
  PUT_EDIT_SP_ALLOWANCE: {
    url: '/e-time/special-allowance',
    method: 'PUT',
    isPublic: false
  },
  POST_CANCEL_SP_ALLOWANCE: {
    url: '/e-time/special-allowance/cancel',
    method: 'POST',
    isPublic: false
  },
  POST_CANCEL_REQ_TIME: {
    url: '/e-time/eTime/cancel',
    method: 'POST',
    isPublic: false
  },
  ETIME_CREATE_DELEGATES: {
    url: '/e-time/delegate',
    method: 'POST',
    isPublic: false
  },
  ETIME_GET_ALL_DELEGATES: {
    url: '/e-time/delegate/',
    isPublic: false
  },
  ETIME_DELETE_DELEGATES: {
    url: '/e-time/delegate/',
    method: 'DELETE',
    isPublic: false
  },
  ETIME_EDIT_UPDATE_DELEGATES: {
    url: '/e-time/delegate/',
    method: 'PUT',
    isPublic: false
  },
  ETIME_GET_SP_ALLOWANCE_SETTING: {
    url: '/e-time/setting/sp-allowance',
    method: 'GET',
    isPublic: false
  },
  ETIME_PUT_SP_ALLOWANCE_SETTING: {
    url: '/e-time/setting/sp-allowance',
    method: 'PUT',
    isPublic: false
  },
  ETIME_GET_SETTING: {
    url: '/e-time/setting/main-setting',
    method: 'GET',
    isPublic: false
  },
  ETIME_PUT_SETTING: {
    url: '/e-time/setting/main-setting',
    method: 'PUT',
    isPublic: false
  },
  ETIME_DELETE_SP_ALLOWANCE_SETTING: {
    url: '/e-time/setting/sp-allowance',
    method: 'DELETE',
    isPublic: false
  },
  ETIME_GET_COSTCENTER_DATA: {
    url: '/e-time/pis-data/cost-center',
    method: 'GET',
    isPublic: false
  },
  ETIME_POST_SP_ALLOWANCE_SETTING: {
    url: '/e-time/setting/sp-allowance',
    method: 'POST',
    isPublic: false
  },
  ETIME_IMPORT_TIME: {
    url: '/e-time/eTime/import',
    method: 'POST',
    isPublic: false,
    isMultipart: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  ETIME_IMPORT_MAPPING_CARD: {
    url: '/e-time/mappingEmpCard/import',
    method: 'POST',
    isPublic: false,
    isMultipart: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  ETIME_IMPORT_SP_ALLOWANCE: {
    url: '/e-time/special-allowance/import',
    method: 'POST',
    isPublic: false,
    isMultipart: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  ETIME_IMPORT_OT: {
    url: '/e-time/ot/import',
    method: 'POST',
    isPublic: false,
    isMultipart: true,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  ETIME_GET_HR_SETTING: {
    url: '/e-time/setting/hr-setting',
    method: 'GET',
    isPublic: false
  },
  ETIME_SYNC_SFTP: {
    url: '/e-time/eTime/sync-sftp',
    method: 'GET',
    isPublic: false
  }
}
